<template>
  <div class="pageMain">
    <div class="flex">
      <!-- 组织项目树 -->

      <OriginTree class="tree" :filterText="filterText" @checked="init">
        <template slot="search">
          <SearchLeft
            @search="search"
            @reset="reset"
            info="项目信息"
            :iconShow="false"
          >
            <el-form :inline="true" :model="searchForm">
              <el-form-item label="" class="formItem">
                <el-input
                  v-model="filterText"
                  size="small"
                  placeholder="项目"
                ></el-input>
              </el-form-item>
            </el-form>
          </SearchLeft>
        </template>
      </OriginTree>

      <!--右边列表  -->
      <div class="form">
        <SearchHead
          @search="search"
          @reset="reset"
          :isExport="true"
          @tableExport="tableExport"
        >
          <el-form :inline="true" :model="searchForm">
            <el-form-item label="项目名称">
              <el-select
                v-model="searchForm.projName"
                size="small"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in projectList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="联系人">
              <el-input
                v-model="searchForm.projHead"
                size="small"
                placeholder="联系人"
              ></el-input>
            </el-form-item>
            <el-form-item label="项目状态">
              <el-select
                v-model="searchForm.projState"
                size="small"
                placeholder="项目状态"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="负责人">
              <el-input
                v-model="searchForm.projHead"
                size="small"
                placeholder="负责人"
              ></el-input>
            </el-form-item>
            <el-form-item label="开始日期">
              <el-date-picker
              class="date"
                v-model="searchForm.startTime"
                type="datetime"
                placeholder="选择开始日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="结束日期">
              <el-date-picker
                class="date"
                v-model="searchForm.endTime"
                type="datetime"
                placeholder="选择结束日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-form>
        </SearchHead>
        <CustomTables
          :data="tableData"
          :headerCellStyle="headerCellStyle"
          @selectionChange="handleSelectionChange"
          @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
      
          :total="total"
          title="项目信息"
        >
          <!-- <template slot="btns">
            <el-button
              type="success"
              size="small"
              @click="addFrom"
              icon="el-icon-plus"
             
              class="btn"
              >新建</el-button
            >
          </template> -->

          <el-table-column type="selection" align="center" width="55">
          </el-table-column>
          <el-table-column prop="projName" label="项目名称" align="center">
          </el-table-column>
          <el-table-column prop="companyName" label="所属公司" align="center">
          </el-table-column>
          <el-table-column prop="projHead" label="负责人" align="center">
          </el-table-column>
          <el-table-column prop="projTel" label="联系电话" align="center">
          </el-table-column>
          <el-table-column prop="projState" label="项目状态" align="center">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.projState == 0" type="success"
                >正常
              </el-tag>
              <el-tag v-if="scope.row.projState == 1" type="danger"
                >停工</el-tag
              >
              <el-tag v-if="scope.row.projState == 2" type="warning"
                >完结</el-tag
              >
              <!-- <el-tag v-if="scope.row.deviceState == 2" type="danger">损坏</el-tag> -->
            </template>
          </el-table-column>
          <el-table-column prop="startTime" label="开工日期" align="center">
          </el-table-column>
          <el-table-column prop="endTime" label="结束日期" align="center">
          </el-table-column>
          <!-- <el-table-column label="详情" align="center" width="100">
            <template slot-scope="scope">
              <span
                @click="detailItem(scope.row)"
                class="detail"
              >查看</span>
            </template>
          </el-table-column> -->
        </CustomTables>
      </div>
    </div>
  </div>
</template>

<script>
import OriginTree from "../../../components/common/OriginTree.vue";
import SearchHead from "../../../components/common/SearchHead";
import CustomTables from "../../../components/common/CustomTables";
import SearchLeft from "../../../components/common/SearchLeft.vue";
import data from "../../../api/modules/data";
import {projectState} from '../../../config/dataStatus'
export default {
  components: {
    OriginTree,
    SearchHead,
    CustomTables,
    SearchLeft,
  },
  name: "statistics",
  data() {
    return {
      searchForm: {
        currPage: 1,
        pageSize: 10,
      },
      tableData: [],
      options:projectState,
      headerCellStyle: {
        backgroundColor: "#f3f2f7",
        color: "#373628",
      },
      total: 0,
      show: true,
      filterText: "",
      projectList:[]
    };
  },
  mounted() {
    this.getProjectList()
  },
  methods: {
    addFrom() {},
    getProjectList(){
      data.GetProjectList().then(res=>{
        console.log('项目列表',res);
        res.data.map(item=>{
          this.projectList.push({
            label:item.projName,
            value:item.projId
          })
        })
        
      })
    },
    init(val) {
      this.searchForm.orgaId = val.orgaId;
      this.orgaId=val.orgaId;
      this.searchForm.currPage = 1;
      this.getList();
    },
    getList() {
      data.getProjReportListByPage(this.searchForm).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.records;
      });
    },
    search() {
      console.log("searchForm", this.searchForm);
      this.searchForm.currPage = 1;
      this.searchForm.pageSize = 10;
      this.getList();
    },
    reset() {
      this.searchForm = {
        currPage: 1,
        pageSize: 10,
        orgaId:this.orgaId
      };
      this.getList();
    },
    tableExport() {
      // 导出
    },
    handleSelectionChange(val) {
      console.log(val);
    },
    detailItem() {
      //详情
    },
    handleCurrentChange(pageIndex) {
      this.searchForm.currPage = pageIndex
      this.getList()
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.currPage = 1
      this.getList()
    },
  },
};
</script>

<style scoped lang="less">
.flex {
  display: flex;
  height: 100%;
  
  // justify-content: space-between;
}
.tree {
  width: 20%;
  height: 96%;
  
}
.form {
  width: 78%;
  margin-left: 2%;
}
.formItem {
  margin-bottom: 0px;
}
.btn {
  background-color: #008f4d;
  // color: #008f4d;
}

.detail {
  color: #008f4d;
}
</style>
